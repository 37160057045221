/* import { createGlobalStyle } from 'styled-components';
import nanumGothicL from './NanumGothic-Light.ttf';
import nanumGothicR from './NanumGothic-Regular.ttf';
import nanumGothicB from './NanumGothic-Bold.ttf';
import nanumGothicEB from './NanumGothic-ExtraBold.ttf';
import helveticaNeueB from './HelveticaNeue-Bold.ttf';
import robotoB from './Roboto-Bold.ttf';

export default createGlobalStyle`
@font-face {
        font-family: 'nanumGothicL';
        src: local('nanumGothicL'), local('nanumGothicL');
        font-style: normal;
        src: url(${nanumGothicL}) format('truetype');
  }
 @font-face {
        font-family: 'nanumGothicR';
        src: local('nanumGothicR'), local('nanumGothicR');
        font-style: normal;
        src: url(${nanumGothicR}) format('truetype');
  }
 @font-face {
        font-family: 'nanumGothicB';
        src: local('nanumGothicB'), local('nanumGothicB');
        font-style: normal;
        src: url(${nanumGothicB}) format('truetype');
  }
 @font-face {
        font-family: 'nanumGothicEB';
        src: local('nanumGothicEB'), local('nanumGothicEB');
        font-style: normal;
        src: url(${nanumGothicEB}) format('truetype');
  }
  @font-face {
        font-family: 'helveticaNeueB';
        src: local('helveticaNeueB'), local('helveticaNeueB');
        font-style: normal;
        src: url(${helveticaNeueB}) format('truetype');
  }
  @font-face {
        font-family: 'robotoB';
        src: local('robotoB'), local('robotoB');
        font-style: normal;
        src: url(${robotoB}) format('truetype');
  }
`; */

@font-face {
  font-family: 'nanumGothicL';
  src: local('nanumGothicL'), local('nanumGothicL');
  font-style: normal;
  src: url(./NanumGothicLight.woff2) format('woff2');
}

@font-face {
  font-family: 'nanumGothicR';
  src: local('nanumGothicR'), local('nanumGothicR');
  font-style: normal;
  src: url(./NanumGothic.woff2) format('woff2');
}

@font-face {
  font-family: 'nanumGothicB';
  src: local('nanumGothicB'), local('nanumGothicB');
  font-style: normal;
  src: url(./NanumGothicBold.woff2) format('woff2');
}

@font-face {
  font-family: 'nanumGothicEB';
  src: local('nanumGothicEB'), local('nanumGothicEB');
  font-style: normal;
  src: url(./NanumGothicExtraBold.woff2) format('woff2');
}
@font-face {
  font-family: 'helveticaNeueB';
  src: local('helveticaNeueB'), local('helveticaNeueB');
  font-style: normal;
  src: url(./HelveticaNeue-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'robotoB';
  src: local('robotoB'), local('robotoB');
  font-style: normal;
  src: url(./Roboto-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'helveticaNeueR';
  src: local('helveticaNeueR'), local('helveticaNeueR');
  font-style: normal;
  src: url(./HelveticaNeue-Medium.ttf) format('truetype');
}
